
const MPLogo = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			//xmlns:xlink="http://www.w3.org/1999/xlink"
			width="285"
			height="70"
			viewBox="45 174 285 70"
			zoomAndPan="magnify"
			preserveAspectRatio="xMidYMid meet"
			version="1.0"
		>
			<defs>
				<g />
			</defs>
			<path
				fill="#000000"
				d="M 134.019531 186.855469 C 133.5 187.660156 132.792969 187.023438 133.242188 186.320312 C 149.878906 160.332031 160.3125 195.585938 193.753906 192.625 C 245.242188 188.070312 288.285156 173.527344 320.320312 186.046875 C 322.554688 186.921875 320.980469 188.753906 319.101562 187.898438 C 292.4375 175.777344 242.90625 188.46875 193.53125 193.609375 C 160.074219 197.089844 150.617188 161.292969 134.019531 186.855469 Z M 134.019531 186.855469 "
				fillOpacity="1"
				fillRule="nonzero"
			/>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(49.558595, 200.249987)">
					<g>
						<path
							d="M 27.121094 0.839844 C 27.238281 -4.5 30.960938 -11.820312 32.21875 -17.519531 C 33.300781 -22.378906 30.238281 -23.28125 28.980469 -23.160156 C 23.21875 -22.5 17.460938 -7.800781 14.941406 -3.238281 C 17.699219 -12.601562 20.460938 -22.921875 14.339844 -23.339844 C 8.878906 -23.101562 6.238281 -15.300781 1.621094 -1.621094 C 2.578125 -7.261719 2.761719 -8.941406 4.140625 -15.121094 C 4.320312 -17.761719 0.300781 -16.621094 -0.0585938 -14.460938 C -1.558594 -5.101562 -2.28125 -0.359375 -2.640625 4.441406 C -2.820312 6.898438 0.660156 5.578125 1.078125 4.019531 C 6.601562 -14.28125 10.199219 -22.621094 14.28125 -22.558594 C 15.960938 -22.5 11.578125 -8.460938 12.121094 -2.761719 C 12.300781 -0.839844 13.980469 -0.238281 15.121094 -1.621094 C 19.078125 -9.121094 24.898438 -22.800781 29.398438 -22.320312 C 30.78125 -21.839844 23.339844 -7.441406 24.78125 0.898438 C 25.679688 6 30.359375 6.78125 34.140625 3.898438 C 39.539062 -0.121094 45.839844 -9.78125 49.140625 -14.101562 C 49.140625 -14.398438 48.359375 -14.398438 48.121094 -14.160156 C 45.539062 -10.859375 38.21875 0.179688 33.058594 3.601562 C 29.640625 5.820312 27 4.320312 27.121094 0.839844 Z M 27.121094 0.839844 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(97.378542, 200.249987)">
					<g>
						<path
							d="M 36.480469 -14.101562 C 36.480469 -14.398438 35.640625 -14.460938 35.460938 -14.160156 C 32.578125 -9.960938 28.679688 -7.199219 19.980469 -6.71875 C 22.140625 -12.480469 22.199219 -21 17.519531 -22.199219 C 15.300781 -22.800781 9.359375 -20.578125 2.761719 -7.679688 C 3.179688 -10.738281 3.660156 -14.699219 4.140625 -17.878906 C 4.261719 -19.980469 0.238281 -18.898438 -0.179688 -15.601562 C -1.261719 -6.898438 -1.5 -1.140625 -1.921875 0.71875 C -12.421875 16.859375 -17.160156 38.941406 -9.539062 38.519531 C -2.28125 38.101562 0.839844 19.140625 2.699219 -5.519531 C 9.359375 -19.199219 15 -21.660156 17.101562 -21.421875 C 19.261719 -21.179688 19.078125 -12.960938 16.078125 -6.898438 C 8.519531 -6.898438 5.820312 -0.421875 8.28125 1.679688 C 11.519531 4.441406 16.921875 0.601562 19.679688 -5.820312 C 27.839844 -6.058594 33.121094 -8.578125 36.480469 -14.101562 Z M -1.800781 1.921875 C -2.941406 17.160156 -4.378906 37.378906 -9.839844 37.859375 C -14.820312 37.199219 -12.601562 20.21875 -1.800781 1.921875 Z M 8.699219 1.140625 C 7.199219 -0.300781 9 -5.941406 15.839844 -6.058594 C 13.320312 -0.0585938 9.601562 1.980469 8.699219 1.140625 Z M 8.699219 1.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(140.26487, 217.664487)">
					<g>
						<path
							d="M 10.40625 -9.4375 L 10.40625 0 L 9.1875 0 L 9.1875 -5.921875 L 5.828125 -1.15625 L 5.796875 -1.15625 L 2.5 -5.75 L 2.5 0 L 1.3125 0 L 1.3125 -9.4375 L 1.328125 -9.4375 L 5.828125 -3.0625 L 10.390625 -9.4375 Z M 10.40625 -9.4375 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(155.236101, 217.664487)">
					<g>
						<path
							d="M 2.546875 -9.140625 L 2.546875 0 L 1.3125 0 L 1.3125 -9.140625 Z M 2.546875 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(162.342472, 217.664487)">
					<g>
						<path
							d="M 8.03125 -0.6875 C 7.726562 -0.488281 7.351562 -0.304688 6.90625 -0.140625 C 6.457031 0.015625 5.96875 0.09375 5.4375 0.09375 C 4.5 0.09375 3.671875 -0.109375 2.953125 -0.515625 C 2.242188 -0.929688 1.695312 -1.488281 1.3125 -2.1875 C 0.9375 -2.882812 0.75 -3.65625 0.75 -4.5 C 0.75 -5.40625 0.941406 -6.21875 1.328125 -6.9375 C 1.710938 -7.65625 2.25 -8.21875 2.9375 -8.625 C 3.625 -9.03125 4.398438 -9.234375 5.265625 -9.234375 C 5.785156 -9.234375 6.265625 -9.164062 6.703125 -9.03125 C 7.148438 -8.894531 7.550781 -8.71875 7.90625 -8.5 L 7.421875 -7.359375 C 6.722656 -7.828125 6.019531 -8.0625 5.3125 -8.0625 C 4.71875 -8.0625 4.171875 -7.90625 3.671875 -7.59375 C 3.171875 -7.28125 2.769531 -6.859375 2.46875 -6.328125 C 2.164062 -5.796875 2.015625 -5.207031 2.015625 -4.5625 C 2.015625 -3.90625 2.148438 -3.3125 2.421875 -2.78125 C 2.703125 -2.25 3.097656 -1.832031 3.609375 -1.53125 C 4.117188 -1.238281 4.707031 -1.09375 5.375 -1.09375 C 6.1875 -1.09375 6.898438 -1.320312 7.515625 -1.78125 Z M 8.03125 -0.6875 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(174.367537, 217.664487)">
					<g>
						<path
							d="M 8.5625 0 L 7.328125 0 L 7.328125 -4 L 2.546875 -4 L 2.546875 0 L 1.3125 0 L 1.3125 -9.140625 L 2.546875 -9.140625 L 2.546875 -5.1875 L 7.328125 -5.1875 L 7.328125 -9.140625 L 8.5625 -9.140625 Z M 8.5625 0 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(187.492676, 217.664487)">
					<g>
						<path
							d="M 7.25 -9.140625 L 7.25 -7.96875 L 2.546875 -7.96875 L 2.546875 -5.1875 L 6.75 -5.1875 L 6.75 -4 L 2.546875 -4 L 2.546875 -1.1875 L 7.4375 -1.1875 L 7.4375 0 L 1.3125 0 L 1.3125 -9.140625 Z M 7.25 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(198.999321, 217.664487)">
					<g>
						<path
							d="M 2.546875 -9.140625 L 2.546875 -1.1875 L 7.1875 -1.1875 L 7.1875 0 L 1.3125 0 L 1.3125 -9.140625 Z M 2.546875 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(210.113982, 217.664487)">
					<g>
						<path
							d="M 2.546875 -9.140625 L 2.546875 -1.1875 L 7.1875 -1.1875 L 7.1875 0 L 1.3125 0 L 1.3125 -9.140625 Z M 2.546875 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(221.228644, 217.664487)">
					<g>
						<path
							d="M 7.25 -9.140625 L 7.25 -7.96875 L 2.546875 -7.96875 L 2.546875 -5.1875 L 6.75 -5.1875 L 6.75 -4 L 2.546875 -4 L 2.546875 -1.1875 L 7.4375 -1.1875 L 7.4375 0 L 1.3125 0 L 1.3125 -9.140625 Z M 7.25 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(232.735288, 217.664487)">
					<g />
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(239.980737, 217.664487)">
					<g>
						<path
							d="M 3.765625 -9.140625 C 4.765625 -9.140625 5.539062 -8.890625 6.09375 -8.390625 C 6.65625 -7.890625 6.9375 -7.195312 6.9375 -6.3125 C 6.9375 -5.789062 6.832031 -5.304688 6.625 -4.859375 C 6.425781 -4.421875 6.09375 -4.0625 5.625 -3.78125 C 5.164062 -3.5 4.585938 -3.359375 3.890625 -3.359375 L 2.546875 -3.359375 L 2.546875 0 L 1.3125 0 L 1.3125 -9.140625 Z M 3.890625 -4.546875 C 4.328125 -4.546875 4.679688 -4.640625 4.953125 -4.828125 C 5.234375 -5.015625 5.429688 -5.238281 5.546875 -5.5 C 5.660156 -5.769531 5.71875 -6.03125 5.71875 -6.28125 C 5.71875 -6.71875 5.570312 -7.109375 5.28125 -7.453125 C 5 -7.796875 4.554688 -7.96875 3.953125 -7.96875 L 2.546875 -7.96875 L 2.546875 -4.546875 Z M 3.890625 -4.546875 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(250.753998, 217.664487)">
					<g>
						<path
							d="M 0.75 -4.5625 C 0.75 -5.394531 0.957031 -6.171875 1.375 -6.890625 C 1.800781 -7.609375 2.367188 -8.175781 3.078125 -8.59375 C 3.785156 -9.019531 4.5625 -9.234375 5.40625 -9.234375 C 6.238281 -9.234375 7.015625 -9.019531 7.734375 -8.59375 C 8.453125 -8.175781 9.023438 -7.609375 9.453125 -6.890625 C 9.878906 -6.171875 10.09375 -5.394531 10.09375 -4.5625 C 10.09375 -3.71875 9.878906 -2.9375 9.453125 -2.21875 C 9.035156 -1.507812 8.460938 -0.945312 7.734375 -0.53125 C 7.015625 -0.113281 6.238281 0.09375 5.40625 0.09375 C 4.5625 0.09375 3.78125 -0.109375 3.0625 -0.515625 C 2.351562 -0.929688 1.789062 -1.492188 1.375 -2.203125 C 0.957031 -2.921875 0.75 -3.707031 0.75 -4.5625 Z M 2.015625 -4.546875 C 2.015625 -3.921875 2.164062 -3.34375 2.46875 -2.8125 C 2.769531 -2.28125 3.179688 -1.859375 3.703125 -1.546875 C 4.234375 -1.242188 4.8125 -1.09375 5.4375 -1.09375 C 6.0625 -1.09375 6.628906 -1.242188 7.140625 -1.546875 C 7.660156 -1.859375 8.070312 -2.28125 8.375 -2.8125 C 8.675781 -3.34375 8.828125 -3.925781 8.828125 -4.5625 C 8.828125 -5.195312 8.671875 -5.78125 8.359375 -6.3125 C 8.054688 -6.851562 7.644531 -7.28125 7.125 -7.59375 C 6.601562 -7.90625 6.03125 -8.0625 5.40625 -8.0625 C 4.78125 -8.0625 4.207031 -7.898438 3.6875 -7.578125 C 3.164062 -7.265625 2.753906 -6.835938 2.453125 -6.296875 C 2.160156 -5.765625 2.015625 -5.179688 2.015625 -4.546875 Z M 2.015625 -4.546875 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(264.840107, 217.664487)">
					<g>
						<path
							d="M 2.546875 -9.140625 L 2.546875 -1.1875 L 7.1875 -1.1875 L 7.1875 0 L 1.3125 0 L 1.3125 -9.140625 Z M 2.546875 -9.140625 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(275.954768, 217.664487)">
					<g>
						<path
							d="M 6.109375 -2.40625 L 2.59375 -2.40625 L 1.625 0 L 0.40625 0 L 4.40625 -9.515625 L 4.46875 -9.515625 L 8.46875 0 L 7.046875 0 Z M 5.6875 -3.46875 L 4.390625 -6.796875 L 3.03125 -3.46875 Z M 5.6875 -3.46875 "
						/>
					</g>
				</g>
			</g>
			<g fill="#000000" fillOpacity="1">
				<g transform="translate(288.068338, 217.664487)">
					<g>
						<path
							d="M 9.28125 -9.140625 L 9.28125 0.296875 L 9.234375 0.296875 L 2.53125 -6.515625 L 2.546875 0 L 1.3125 0 L 1.3125 -9.421875 L 1.375 -9.421875 L 8.078125 -2.53125 L 8.046875 -9.140625 Z M 9.28125 -9.140625 "
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default MPLogo;
